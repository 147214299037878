export interface LoginPostData {
	appVersion: string;
	deviceToken: string;
	deviceType: number;
	osVersion: string;
	usuario: string;
	senha: string;
}

export interface Permissao {
	nome: string;
	id: number;
	codigo: string;
}

export interface Cliente {
	nome: string;
	empresaId: number;
	id: number;
}

export interface Empresa {
	cnpj: string;
	nome: string;
	id: number;
}

export interface DroneLogin {
	clienteId: number;
	empresaId: number;
	serial: string;
	nome: string;
	id: number;
}

export interface Parametros {
	baseLatitude: number;
	baseLongitude: number;
	alturaMinima: number;
	velocidade: number;
	bateriaMinima: number;
	bateriaRCMinima: number;
	bateriaMobileMinima: number;
	maximoVento: number;
	gpsCountMinimo: number;
	gpsSinalMinimo: number;
	stream: string;
	streamHost: string;
	streamPort: number;
	streamType: number;
}

export interface LoginReplyModel {
	hashLogin: string;
	usuarioId: number;
	email: string;
	nome: string;
	telefone: string;
	tipo: string;
	permissoes: Permissao[];
	cliente: Cliente[];
	empresa: Empresa;
	parametros: Parametros;
	drones: DroneLogin[];
}

export class LoginModel {
	hashLogin!: string;
	usuarioId!: number;
	email!: string;
	nome!: string;
	telefone!: string;
	tipo!: number;
	permissoes!: Permissao[];
	cliente!: Cliente[];
	empresa!: Empresa;
	parametros!: Parametros;
	drones!: DroneLogin[];

	constructor(user: LoginReplyModel) {
		Object.assign(this, user);
	}

	canCreateEmpresa() {
		return (this.permissoes || []).findIndex((x) => x.codigo === "ACR_EMP") >= 0;
	}

	canCreateCliente() {
		return (this.permissoes || []).findIndex((x) => x.codigo === "ACR_CLI") >= 0;
	}

	canCreateAdmin() {
		return (this.permissoes || []).findIndex((x) => x.codigo === "ACR_ADM") >= 0;
	}

	getCliente(id: number) {
		return this.cliente.find((item: any) => {
			return item.id === id;
		});
	}
}
