import { useMemo } from "react";
import VideoJS from "../VideoJS";
import { LiveViewModel } from "../../models/LiveViewModel";

type LiveCardProps = {
	data: LiveViewModel;
	onClick: () => void;
};

export default function LiveCard(props: LiveCardProps) {
	const videoJsOption = useMemo(() => {
		return {
			autoplay: true,
			playsinline: true,
			controls: false,
			responsive: true,
			fill: true,
			sources: props.data.parametro.liveURL,
			poster: process.env.PUBLIC_URL + "/logo_escuro.png",
		};
	}, [props.data.parametro.liveURL]);

	return (
		<div className="position-relative d-flex justify-content-center rounded-3 shadow overflow-hidden" style={{ width: "100%", height: "100%" }}>
			<div
				className="position-absolute fs-6 text-white p-2 lh-1 rounded"
				style={{ zIndex: 10, top: 10, background: "rgba(31, 37, 60, 0.5)", backdropFilter: "blur(5px)", cursor: "pointer" }}
				onClick={props.onClick}
			>
				{props.data.cliente && `${props.data.cliente.nome} - `}
				{props.data.drone.nome}
			</div>
			<div
				className="position-absolute d-flex flex-wrap justify-content-center gap-2 fs-6 text-white p-2 lh-1 rounded"
				style={{ zIndex: 10, bottom: 10, background: "rgba(31, 37, 60, 0.5)", backdropFilter: "blur(5px)" }}
			>
				<div className="small">
					<span className="fw-lighter">Altura:</span> {props.data.altura.toFixed(2)} <span className="small">m</span>
				</div>
				<div className="small">
					<span className="fw-lighter">Velocidade:</span> {props.data.velocidade.toFixed(2)} <span className="small">m/s</span>
				</div>
				<div className="small">
					<span className="fw-lighter">GPS:</span> {props.data.gpsSignal} <span className="small">/5</span> ({props.data.gpsCount})
				</div>
			</div>
			<VideoJS options={videoJsOption} className="bg-dark" style={{ width: "100%", height: "100%" }} />
		</div>
	);
}
