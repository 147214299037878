import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import GoogleMap, { Map } from "google-maps-react-markers";
import Icon from "@mdi/react";
import { mdiAlphaHCircleOutline, mdiChevronLeft } from "@mdi/js";
import { ReactComponent as IconLogo } from "../../icons/logo_icone.svg";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";

import Marker from "../../components/Marker";
import IconSVG from "../../components/IconSVG";
import VideoJS from "../../components/VideoJS";
import { LiveViewModel } from "../../models/LiveViewModel";

const toastTitle = "Live";

type LiveProps = {
	data: LiveViewModel;
	onClose: () => void;
};

export default function Live({ data, onClose }: LiveProps) {
	const navigate = useNavigate();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const containerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<Map>();

	const [formHomeLatitude, setFormHomeLatitude] = useState<number>();
	const [formHomeLongitude, setFormHomeLongitude] = useState<number>();
	const [formRota, setFormRota] = useState<google.maps.Polygon>();

	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);

	useQuery(["cliente", "home", data.cliente.id], () => fetchDataClienteHome(data.cliente.id));
	useQuery(["missao", data?.missao.id, "rota"], () => fetchDataMissaoRota(data?.missao.id), {
		enabled: !!data?.missao.id,
	});
	useQuery(["missao", data?.missao.id, "home"], () => fetchDataMissaoHome(data?.missao.id), {
		enabled: !!data?.missao.id,
	});

	const videoJsOption = useMemo(() => {
		return {
			autoplay: true,
			playsinline: true,
			controls: false,
			responsive: true,
			fill: true,
			sources: data.parametro.liveURL,
			poster: process.env.PUBLIC_URL + "/logo_escuro.png",
		};
	}, [data.parametro.liveURL]);

	useEffect(() => {
		if (formRota && mapRef.current) {
			formRota.setMap(mapRef.current);
		}

		return () => {
			if (formRota) {
				formRota.setMap(null);
			}
		};
		// eslint-disable-next-line
	}, [formRota, mapRef.current]);

	useEffect(() => {
		if (containerRef.current) {
			setContainerWidth(containerRef.current.clientWidth);
			setContainerHeight(containerRef.current.clientHeight);
		}
		// eslint-disable-next-line
	}, [containerRef.current]);

	async function fetchDataClienteHome(clienteId: any) {
		let resp = await apiService.getHome(clienteId);
		if (resp.Result === 1 && resp.Data) {
			setFormHomeLatitude(resp.Data.latitude);
			setFormHomeLongitude(resp.Data.longitude);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	async function fetchDataMissaoRota(missaoId: any) {
		let resp = await apiService.getRotaMissao(missaoId);
		if (resp.Result === 1 && resp.Data) {
			if (mapRef.current) {
				let rota = new google.maps.Polygon({
					paths: resp.Data.map((item) => {
						return { lat: item.latitude, lng: item.longitude };
					}),
					strokeColor: "#dc3545",
					strokeOpacity: 1,
					strokeWeight: 2,
					fillOpacity: 0,
				});
				var bounds = new google.maps.LatLngBounds();
				var paths = rota.getPaths();
				var path;
				for (var i = 0; i < paths.getLength(); i++) {
					path = paths.getAt(i);
					for (var ii = 0; ii < path.getLength(); ii++) {
						bounds.extend(path.getAt(ii));
					}
				}
				mapRef.current.fitBounds(bounds);

				setFormRota(rota);
			}
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}
		return resp;
	}

	async function fetchDataMissaoHome(missaoId: any) {
		let resp = await apiService.GetHomeMissao(missaoId);
		if (resp.Result === 1 && resp.Data) {
			setFormHomeLatitude(resp.Data.latitude);
			setFormHomeLongitude(resp.Data.longitude);
		}
		return resp;
	}

	function handleMapLoad({ map }: { map: Map }) {
		mapRef.current = map;
	}

	function handleVoltar() {
		navigate("/lives");
	}

	return (
		<div className="d-flex gap-2 w-100" style={{ minHeight: "100%" }}>
			<div className="w-100">
				<div ref={containerRef} className="position-relative" style={{ height: "100%" }}>
					<Button variant="air-dark" className="rounded-circle p-0 position-absolute" onClick={onClose} style={{ zIndex: 2, left: -5, top: -5, width: 42, height: 42 }}>
						<Icon path={mdiChevronLeft} size={1} />
					</Button>
					<div className={`d-inline-block position-absolute rounded-3 shadow overflow-hidden`} style={{ width: containerWidth, height: containerHeight, zIndex: 1 }}>
						<VideoJS options={videoJsOption} className="bg-dark" style={{ width: "100%", height: "100%" }} />
					</div>
				</div>
			</div>
			<div className="d-flex flex-column gap-2" style={{ width: 300, height: "100%" }}>
				<div className={`w-100 rounded-3 shadow `} style={{ aspectRatio: 2 / 1.5 }}>
					{data.lat && data.lng && (
						<GoogleMap
							apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
							defaultZoom={15}
							defaultCenter={{ lat: data.lat!, lng: data.lng! }}
							options={{ fullscreenControl: false, streetViewControl: false, zoomControl: false, mapTypeControl: false, mapTypeId: "hybrid" }}
							onGoogleApiLoaded={handleMapLoad}
							style={{ height: "100%" }}
						>
							<Marker lat={formHomeLatitude} lng={formHomeLongitude} style={{ zIndex: 9 }}>
								<Icon path={mdiAlphaHCircleOutline} size={1.5} className="text-danger bg-white rounded-circle" style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }} />
							</Marker>
							<Marker lat={data.lat} lng={data.lng} style={{ zIndex: 1100 }}>
								<IconSVG path={IconLogo} style={{ width: 35, height: 35, filter: "drop-shadow( 0 0 4px rgba(0, 0, 0, 1))" }} />
							</Marker>
						</GoogleMap>
					)}
				</div>
				<div className="d-flex flex-fill flex-column justify-content-start p-3 small lh-1 text-white rounded-3 shadow gap-2" style={{ background: "rgba(31, 37, 60, 0.5)" }}>
					<Form.Group className="fw-medium mb-1">
						Cliente: <span className={`fw-light`}>{data.cliente.nome}</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Missão: <span className={`fw-light`}>{data.missao.nome}</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Piloto: <span className={`fw-light`}>{data.piloto.nome}</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Drone: <span className={`fw-light`}>{data.drone.nome}</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Altura:{" "}
						<span className={`fw-light`}>
							{data?.altura.toFixed(1)} <span className="small">m</span>
						</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Velocidade:{" "}
						<span className={`fw-light`}>
							{data?.velocidade.toFixed(1)} <span className="small">m/s</span>
						</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Latitude: <span className={`fw-light`}>{data?.lat.toFixed(12)}&nbsp;</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Longitude: <span className={`fw-light`}>{data?.lng.toFixed(12)}&nbsp;</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Bateria iPhone:{" "}
						<span className={`fw-light`}>
							{data?.bateriaMobile} <span className="small">%</span>
						</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Bateria:{" "}
						<span className={`fw-light`}>
							{data?.bateria} <span className="small">%</span>
						</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Bateria RC:{" "}
						<span className={`fw-light`}>
							{data?.bateriaRc} <span className="small">%</span>
						</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Sinal GPS:{" "}
						<span className={`fw-light`}>
							{data?.gpsSignal}
							<span className="small">/5</span> ({data?.gpsCount})&nbsp;
						</span>
					</Form.Group>
					<Form.Group className="fw-medium mb-1">
						Status:{" "}
						<span className={`d-flex flex-wrap gap-1 fw-light`}>
							{data?.decolando === 1 && (
								<Badge text="white" bg="air-purple">
									Decolando
								</Badge>
							)}
							{data?.pousando === 1 && (
								<Badge text="white" bg="air-purple">
									Pousando
								</Badge>
							)}
							{data?.retornando === 1 && (
								<Badge text="white" bg="air-purple">
									Retornando
								</Badge>
							)}
							{data?.pausado === 1 && (
								<Badge text="white" bg="air-purple">
									Pausado
								</Badge>
							)}
							{data?.voando === 1 && (
								<Badge text="white" bg="air-purple">
									Voando
								</Badge>
							)}
						</span>
					</Form.Group>
				</div>
			</div>
		</div>
	);
}
