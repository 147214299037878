import { ReactNode } from "react";
import { Container } from "react-bootstrap";
import Header from "../Header";
import Body from "../Body";

type LayoutProps = {
	children: ReactNode;
};

export default function Layout({ children }: LayoutProps) {
	return (
		<Container fluid className="layout" style={{ minHeight: "100dvh" }}>
			<Body>
				<Header />
				{children}
			</Body>
		</Container>
	);
}
