import { BrowserRouter, Route, Routes } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import "./utils/ArrayAdds";
import "./utils/Strings";
import "./App.scss";

import AuthProvider from "./context/AuthContext";
import ToastProvider from "./context/ToastContext";
import ScrollToTop from "./components/ScrollToTop";
import RouteAuth from "./components/RouteAuth";

import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";

registerLocale("ptBR", ptBR);
setDefaultLocale("ptBR");

function App() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<ScrollToTop />
			<AuthProvider>
				<ToastProvider>
					<Routes>
						<Route path="/" element={<Login />} />

						<Route
							path="/dashboard"
							element={
								<RouteAuth>
									<Dashboard />
								</RouteAuth>
							}
						/>
					</Routes>
				</ToastProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default App;
