import { Vector3 } from "../utils/Vector";

const EARTHRADIUS = 6371008.8;

export class MissaoWaypoint {
	latitude!: number;
	longitude!: number;
	altura!: number;
	nome!: string;
	id?: number;

	distanceOf(other: MissaoWaypoint): number {
		return this.toVector().distanceOf(other.toVector());
	}

	toVector() {
		return Vector3.FromPolar(this.latitude, this.longitude, EARTHRADIUS + this.altura);
	}

	isEqualLatLng(other: MissaoWaypoint): boolean {
		if (this.latitude !== other.latitude || this.longitude !== other.longitude) return false;
		return true;
	}
}

export class MissaoModel {
	codigo!: string;
	waypoints!: MissaoWaypoint[];
	dataTicks!: number;
	descricao!: string;
	ativo!: number;
	nome!: string;
	id!: number;

	computeTotalDistance(homeLat: number, homeLng: number, baseHeight: number) {
		if (this.waypoints.length === 0) return 0;
		var distance = 0; //2*baseHeight;
		distance = this.waypoints
			.map((x) => {
				return { way: x, dist: 0 };
			})
			.reduce((acc, cV) => {
				var delta = acc.way.distanceOf(cV.way);
				return { way: cV.way, dist: acc.dist + delta };
			}).dist;
		var homeV = Vector3.FromPolar(homeLat, homeLng, EARTHRADIUS + baseHeight);
		distance += homeV.distanceOf(this.waypoints.first().toVector());
		distance += homeV.distanceOf(this.waypoints.last().toVector());
		return distance;
	}
}
