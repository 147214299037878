import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import GoogleMap, { Map } from "google-maps-react-markers";
import ScaleText from "react-scale-text";
import Icon from "@mdi/react";
import { mdiAirplane, mdiAlphaHCircleOutline, mdiClock, mdiMapMarkerDistance } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import { gridTemplate } from "../../config/defines";

import LiveCard from "../../components/LiveCard";
import Layout from "../../components/Layout";
import Marker from "../../components/Marker";
import DashboardCard from "../../components/DashboardCard";
import { Spinner } from "react-bootstrap";
import Live from "../../components/Live";
import { LiveViewModel } from "../../models/LiveViewModel";

export default function Dashboard() {
	const { user, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formCliente, setFormCliente] = useState(0);
	const [formMap, setFormMap] = useState<google.maps.Map>();
	const [formBaseLatitude, setFormBaseLatitude] = useState<number>();
	const [formBaseLongitude, setFormBaseLongitude] = useState<number>();
	const [formSOSLatitude, setFormSOSLatitude] = useState<number>();
	const [formSOSLongitude, setFormSOSLongitude] = useState<number>();
	const [formLive, setFormLive] = useState<LiveViewModel>();
	// const [formCount, setFormCount] = useState(1);

	const queryParametros = useQuery(["parametros", user!.cliente[formCliente]!.id], () => fetchDataParametros(), { refetchOnWindowFocus: false });
	const queryResumo = useQuery(["resumo", user!.cliente[formCliente]!.id], () => fetchDataResumo(), { refetchOnWindowFocus: false });

	const queryLives = useQuery(["lives"], () => fetchDataLives(), {
		refetchOnWindowFocus: false,
		refetchInterval: 10000,
	});

	const livesCount = queryLives.data?.Data?.length ?? 0;
	const livesGrid = gridTemplate(livesCount);
	let livesStatus = false;
	if (livesCount >= 1) {
		livesStatus = true;
	}

	useEffect(() => {
		let interval = setInterval(() => {
			if (!livesStatus) {
				setFormCliente((p) => {
					return p + 1 === user!.cliente.length ? 0 : p + 1;
				});
			}
		}, 10000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, [livesStatus]);

	useEffect(() => {
		if (formCliente && queryParametros.data && queryParametros.data.Data?.first()) {
			let latLng = { lat: queryParametros.data.Data?.first().baseLatitude, lng: queryParametros.data.Data?.first().baseLongitude };
			formMap!.setZoom(11);
			setTimeout(() => {
				formMap!.panTo(latLng);
			}, 1000);
			setTimeout(() => {
				formMap!.setZoom(15);
			}, 2000);
			setFormBaseLatitude(queryParametros.data.Data.first().baseLatitude);
			setFormBaseLongitude(queryParametros.data.Data.first().baseLongitude);
			setFormSOSLatitude(queryParametros.data.Data.first().sosLatitude);
			setFormSOSLongitude(queryParametros.data.Data.first().sosLongitude);
		}
		// eslint-disable-next-line
	}, [formCliente, queryParametros.data]);

	async function fetchDataParametros() {
		return await apiService.GetParametros(user!.cliente[formCliente]!.id);
	}

	async function fetchDataResumo() {
		return await apiService.getResumo(user!.cliente[formCliente]!.id);
	}

	async function fetchDataLives() {
		let resp = await apiService.GetLives();
		let formLiveId = resp.Data?.find((data) => {
			return data.vooId === formLive?.vooId;
		});
		if (formLiveId === undefined) {
			setFormLive(undefined);
		}
		// let data = resp.Data![0];
		// for (let i = 0; i < formCount; i++) {
		// 	resp.Data![i] = data;
		// }
		return resp;
	}

	function handleMapLoad({ map }: { map: Map; maps: any }) {
		var baseLatitude = formBaseLatitude;
		var baseLongitude = formBaseLongitude;
		var sosLatitude = formSOSLatitude;
		var sosLongitude = formSOSLongitude;

		map.addListener("click", (e: any) => {
			console.log(e.latLng.lat, baseLatitude, baseLongitude);
			if (!baseLatitude && !baseLongitude) {
				baseLatitude = e.latLng.lat();
				baseLongitude = e.latLng.lng();
				setFormBaseLatitude(baseLatitude);
				setFormBaseLongitude(baseLongitude);
			} else if (!sosLatitude && !sosLongitude) {
				sosLatitude = e.latLng.lat();
				sosLongitude = e.latLng.lng();
				setFormSOSLatitude(sosLatitude);
				setFormSOSLongitude(sosLongitude);
			}
		});

		setFormMap(map);
	}

	return (
		<Layout>
			<div className={`position-fixed p-0`} style={{ inset: 0, zIndex: -1 }}>
				<div
					className={`position-absolute`}
					style={{
						inset: 0,
						zIndex: 1,
						background: "radial-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.9)",
						backdropFilter: livesStatus ? "blur(5px)" : "",
					}}
				></div>
				<GoogleMap
					apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
					defaultZoom={15}
					defaultCenter={{ lat: -30.033056, lng: -51.23 }}
					options={{
						fullscreenControl: false,
						streetViewControl: false,
						zoomControl: false,
						mapTypeControl: false,
						mapTypeId: "hybrid",
					}}
					onGoogleApiLoaded={handleMapLoad}
				>
					{formBaseLatitude && formBaseLongitude && (
						<Marker
							lat={formBaseLatitude}
							lng={formBaseLongitude}
							draggable={true}
							onDragEnd={(e: any, { latLng }: any) => {
								setFormBaseLatitude(latLng.lat);
								setFormBaseLongitude(latLng.lng);
							}}
						>
							<Icon path={mdiAlphaHCircleOutline} size={1.5} className="text-danger bg-white rounded-circle" style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }} />
						</Marker>
					)}
					{formSOSLatitude && formSOSLongitude && (
						<Marker
							lat={formSOSLatitude}
							lng={formSOSLongitude}
							draggable={true}
							onDragEnd={(e: any, { latLng }: any) => {
								setFormSOSLatitude(latLng.lat);
								setFormSOSLongitude(latLng.lng);
							}}
						>
							<Icon path={mdiAlphaHCircleOutline} size={1.5} className="text-air-blue bg-white rounded-circle" style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }} />
						</Marker>
					)}
				</GoogleMap>
			</div>
			{formLive === undefined ? (
				<div className="d-flex p-0" style={{ height: "calc(100dvh - 56px)", marginTop: 56 }}>
					{!livesStatus && (
						<div className="d-flex flex-column flex-fill align-items-center justify-content-evenly">
							<div
								className="d-flex flex-column align-items-center fs-1 fw-bold text-white py-3 px-4 lh-1 rounded-3 shadow"
								style={{ background: "rgba(31, 37, 60, 0.5)", backdropFilter: "blur(5px)" }}
							>
								{user!.cliente[formCliente].nome}
							</div>
							<div className="d-flex gap-4 flex-wrap justify-content-center">
								<DashboardCard
									icon={mdiAirplane}
									title="Número de Voos"
									value={
										<div className="fw-bold" style={{ width: "100%", height: 60 }}>
											<ScaleText>{queryResumo.isLoading ? <Spinner /> : queryResumo.data?.Data?.first()?.numeroVoos ?? "-"}</ScaleText>
										</div>
									}
								/>
								<DashboardCard
									icon={mdiClock}
									title="Horas de Vôo"
									value={
										<div className="fw-bold" style={{ width: "100%", height: 60 }}>
											<ScaleText>{queryResumo.isLoading ? <Spinner /> : queryResumo.data?.Data?.first()?.tempoVoo ?? "-"}</ScaleText>
										</div>
									}
								/>
								<DashboardCard
									icon={mdiMapMarkerDistance}
									title="Distância de Vôo"
									value={
										<div className="fw-bold" style={{ width: "100%", height: 60 }}>
											<ScaleText>{queryResumo.isLoading ? <Spinner /> : queryResumo.data?.Data?.first()?.distanciaVoo ?? "-"}</ScaleText>
										</div>
									}
								/>
							</div>
						</div>
					)}
					{livesStatus && (
						<div className="flex-fill w-100 p-2" style={{ display: "grid", gridTemplateColumns: livesGrid.gridTemplateColumns, gap: 5 }}>
							{queryLives.data?.Data?.map((data, index) => {
								return (
									<div key={data.vooId ?? data.drone.id} style={{ gridColumn: livesGrid.gridColumns[index], gridRow: livesGrid.gridRows ? livesGrid.gridRows[index] : "auto" }}>
										<LiveCard
											data={data}
											onClick={() => {
												setFormLive(data);
											}}
										/>
									</div>
								);
							})}
						</div>
					)}
					{/* <div className="d-flex flex-column justify-content-center gap-3 p-2" style={{ background: "rgba(31, 37, 60, 0.8)", backdropFilter: "blur(5px)" }}>
						<Button
							variant={"outline-light"}
							size="sm"
							onClick={() => {
								setFormCount(formCount + 1);
							}}
							style={{ height: 32 }}
						>
							+
						</Button>
						<Button
							variant={"outline-light"}
							size="sm"
							onClick={() => {
								setFormCount(formCount - 1);
							}}
							style={{ height: 32 }}
						>
							-
						</Button>
					</div> */}
				</div>
			) : (
				<div className="p-2" style={{ width: "100%", height: "calc(100dvh - 56px)", marginTop: 56 }}>
					<Live
						data={formLive}
						onClose={() => {
							setFormLive(undefined);
						}}
					/>
				</div>
			)}
		</Layout>
	);
}
