import {
	mdiAccountTieHatOutline,
	mdiAirplane,
	mdiBroadcast,
	mdiCogOutline,
	mdiDomainPlus,
	mdiMapMarkerPath,
	mdiQuadcopter,
	mdiTextBoxOutline,
	mdiViewDashboardOutline,
	mdiViewDashboardVariantOutline,
} from "@mdi/js";

export const screens = [
	{
		menu: null,
		name: "Dashboard",
		description: "",
		icon: mdiViewDashboardOutline,
		route: "/dashboard",
		showSidebar: true,
		showDashboard: false,
	},
	{
		menu: null,
		name: "Status",
		description: "Lista de status",
		icon: mdiViewDashboardVariantOutline,
		route: "/status",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Lives",
		description: "Lives de voos",
		icon: mdiBroadcast,
		route: "/lives",
		routeSecret: "/livesAdmin",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Voos",
		description: "Lista de voos",
		icon: mdiAirplane,
		route: "/voos",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Pilotos",
		description: "Lista de pilotos",
		icon: mdiAccountTieHatOutline,
		route: "/pilotos",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Drones",
		description: "Lista de drones",
		icon: mdiQuadcopter,
		route: "/drones",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Missões",
		description: "Lista de missões",
		icon: mdiMapMarkerPath,
		route: "/missoes",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Logs Diários",
		description: "Lista de logs diários",
		icon: mdiTextBoxOutline,
		route: "/logs",
		showSidebar: true,
		showDashboard: true,
	},
	// {
	// 	menu: null,
	// 	name: "Baterias",
	// 	description: "Lista de baterias",
	// 	icon: mdiBatteryHigh,
	// 	route: "/baterias",
	// 	showSidebar: true,
	// 	showDashboard: true,
	// },
	{
		menu: null,
		name: "Configurações",
		description: "Lista de configurações",
		icon: mdiCogOutline,
		route: "/configuracoesFormulario",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Cadastros",
		description: "Lista de cadastros",
		icon: mdiDomainPlus,
		route: "/cadastros",
		showSidebar: false,
		showDashboard: false,
	},
];
