import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { queryClient } from "../services/QueryClientService";
import { LoginModel } from "../models/LoginModel";
import { ResumoClienteModel } from "../models/ResumoClienteModel";
import { screens } from "../config/screens";
import moment from "moment";
import { TokenRefreshInterval } from "../config/defines";
import ApiService from "../services/ApiService";

type AuthContextProps = {
	user: LoginModel | undefined;
	cliente: ResumoClienteModel | undefined;
	routeActive: string;
	handleLogin: (user: LoginModel) => void;
	handleLogout: () => void;
	handleCliente: (cliente: ResumoClienteModel) => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth() {
	return useContext(AuthContext);
}

type AuthProviderProps = {
	children: ReactNode;
};

const locations = screens.map((screen) => {
	return screen.route;
});

export default function AuthProvider({ children }: AuthProviderProps) {
	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<LoginModel>();
	const [cliente, setCliente] = useState<ResumoClienteModel>();
	const [routeActive, setRouteActive] = useState<string>("");
	const handleTokenRefreshIntervalRef = useRef(false);

	useEffect(() => {
		if (locations.includes(location.pathname)) {
			setRouteActive(location.pathname);
		}
	}, [location]);

	useEffect(() => {
		let sessionUser = sessionStorage.getItem("user");
		if (sessionUser) {
			let userJson = JSON.parse(sessionUser);
			let user = new LoginModel(userJson);
			setUser(user);
		}

		let sessionCliente = sessionStorage.getItem("cliente");
		if (sessionCliente) {
			let clienteInt = JSON.parse(sessionCliente);
			setCliente(clienteInt);
		}

		setLoading(false);
	}, []);

	useEffect(() => {
		if (user && handleTokenRefreshIntervalRef.current === false) {
			handleTokenRefreshIntervalRef.current = true;
			handleTokenRefreshInterval();
		}
		// eslint-disable-next-line
	}, [user]);

	async function handleLogin(user: LoginModel) {
		sessionStorage.setItem("user", JSON.stringify(user));
		sessionStorage.setItem("tokenRefresh", moment().toISOString());
		setUser(user);
	}

	function handleLogout() {
		setUser(undefined);
		setCliente(undefined);
		sessionStorage.removeItem("user");
		sessionStorage.removeItem("cliente");
		sessionStorage.removeItem("tokenRefresh");
		queryClient.invalidateQueries();
		navigate("/");
		return;
	}

	async function handleCliente(cliente: ResumoClienteModel) {
		sessionStorage.setItem("cliente", JSON.stringify(cliente));
		queryClient.invalidateQueries();
		setCliente(cliente);
	}

	function handleTokenRefreshInterval() {
		console.log("handleTokenRefreshInterval");
		let sessionTokenRefresh = sessionStorage.getItem("tokenRefresh");
		if (sessionTokenRefresh) {
			let minutesDiff = moment().diff(moment(sessionTokenRefresh), "minutes");
			if (minutesDiff > TokenRefreshInterval) {
				handleTokenRefresh();
			} else {
				setTimeout(() => {
					handleTokenRefresh();
				}, (TokenRefreshInterval - minutesDiff) * 1000 * 60);
			}
		}
	}

	async function handleTokenRefresh() {
		try {
			const apiService = new ApiService(handleLogout);
			let resp = await apiService.getTokenRefresh();
			if (user && resp.Data) {
				let userData = new LoginModel(user as any);
				userData.hashLogin = resp.Data;
				sessionStorage.setItem("user", JSON.stringify(userData));
				sessionStorage.setItem("tokenRefresh", moment().toISOString());
				setUser(userData);
			} else {
				handleLogout();
			}
		} catch (error: any) {
			handleLogout();
		}

		setTimeout(() => {
			handleTokenRefresh();
		}, TokenRefreshInterval * 1000 * 60);
	}

	const value = {
		user,
		cliente,
		routeActive,
		handleLogin,
		handleLogout,
		handleCliente,
	};

	if (loading) {
		return <></>;
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
